@import './layout.styles.css';
@import './components.styles.css';
@import './tables.styles.css';
@import './templates.styles.css';


@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    overflow: hidden;
    overscroll-behavior: none;
}

.field-input{@apply w-full text-sm h-10 p-1 border border-gray-400 rounded-md  text-gray-600 outline-none bg-white disabled:bg-gray-200}
.field-input.error{@apply border-red-500}
.field-select{@apply w-full h-8 text-sm p-1 border border-gray-400 rounded-md  text-gray-600 outline-none bg-white}

/* Buttons styles */
.btn {@apply h-10 text-sm  p-2 rounded-md transition-all ease-in-out duration-200 disabled:bg-gray-50}
.btn.flex-button {@apply flex items-center justify-center}
.btn.primary {@apply bg-blue-500 font-semibold shadow-md  text-white hover:bg-blue-600  hover:shadow-lg disabled:bg-gray-400 }
.btn.secondary {@apply bg-gray-100 font-semibold shadow-md  hover:bg-gray-200 hover:shadow-lg disabled:bg-gray-400 disabled:text-gray-50 }
.btn.tertiary {@apply bg-white  text-blue-500 shadow-md hover:shadow-lg  hover:text-blue-600}
.btn.cuartiary {@apply font-semibold text-blue-500  hover:text-blue-600}
.btn.danger {@apply font-semibold text-yellow-500 border  hover:text-yellow-600 disabled:text-gray-400}
.btn.success {@apply font-semibold text-green-500 border hover:text-green-600 disabled:text-gray-400}

/*Esta propiedad le da mas margen y espacio al toolbar de las tablas*/
.shipping-table .header-toolbar,  .shipping-table .dx-toolbar .dx-toolbar-items-container {
    height: 120px;
    margin-bottom: 60px;
}
#grid .dx-command-select{
    width: 120px;
    min-width: 120px;
}

.packing-table-container .dx-toolbar{
    height: 120px;
}
.packing-table-container .dx-datagrid .dx-datagrid-table .dx-row .dx-command-expand {
    width: 10px;
    min-width: 10px;
}

.packing-table-container .dx-datagrid .dx-datagrid-table .dx-row .dx-command-select{
    width: 10px;
}

.animate-ping {animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite}
.animate-spin {
    animation: spin 1s linear infinite;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row td {
    color: white !important;
    background-color: gray !important;
}
