/*MAIN LAYOUT STYLE*/
.main-layout {
    display: grid;
    grid-template-columns: 3rem 1fr;
    grid-template-rows: repeat(2, 3rem) 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.main-layout-header {
    grid-area: 1 / 1 / 2 / 3;
}

.main-layout-menu {
    grid-area: 2 / 1 / 3 / 2;
}

.main-layout-toolbar {
    grid-area: 3 / 1 / 4 / 2;
}

.main-layout-main {
    grid-area: 2 / 2 / 4 / 3;
    overflow: auto;
}

@media (max-width: 768px) {
    .main-layout-header {
        grid-area: 1 / 1 / 2 / 3;
    }
    
    .main-layout-menu {
        grid-area: 2 / 1 / 3 / 2;
    }
    
    .main-layout-toolbar {
        grid-area: 2 / 2 / 3 / 3;
    }
    
    .main-layout-main {
        grid-area: 3 / 1 / 4 / 3;
        overflow: auto;
    }
}
.main-layout-menu.menu, .main-layout-toolbar.toolbar { @apply p-2 bg-black-light}
.main-layout-menu.menu{ @apply z-20}
.main-layout-toolbar.toolbar {@apply z-20 flex flex-row items-center  md:flex-col }