/***************************HEADER AND NAVIGATION STYLES**************************/
.header{
    @apply sticky top-0 w-full h-12 bg-black-shape p-2 text-white z-20
}

.header--menu {
    @apply absolute top-12 right-0 w-56 p-2 h-fit transform transition duration-200 ease-in-out bg-black-light text-white
    shadow-md rounded-bl-md text-sm
}

.header--menu.hide{
    @apply translate-x-full
}

.header--navigation{
    @apply fixed w-72 h-screen top-0 left-0 p-2 bg-black-light text-white transform transition duration-200 ease-in-out shadow-md
}

.header--navigation.hide{
    @apply -translate-x-full
}

.header--navigation--nav {@apply text-white flex-1 overflow-y-auto }
.header--navigation--nav > ul {@apply block mb-4 space-y-4 }
.header--navigation--nav ul li ul > li {@apply mb-1}
.header--navigation--nav ul li ul > li a {@apply block ml-6 p-2 hover:bg-blue-600 rounded-md}
.header--navigation--nav ul li ul > li .active{@apply bg-blue-600}

/*********************************************************************************/




.header-menu {@apply fixed top-12 right-0 w-56 h-fit p-2 transform transition duration-200 ease-in-out bg-black-light text-white
    shadow-md rounded-bl-md z-20
}

.header-menu.hide {@apply translate-x-full}

.aside-menu {@apply fixed w-full  sm:w-80 z-30 p-2 h-screen left-0 top-0 transform transition duration-200 ease-in-out
    bg-black-light text-white shadow-md flex flex-col
}
.aside-menu.hide{ @apply -translate-x-full}

.tabs-buttons{
    @apply p-1 bg-gray-100 rounded-t-md border border-b-0 cursor-pointer
}

.tabs-buttons.selected{
    @apply bg-blue-500 border-0 text-white font-bold
}


.full-h-screen {@apply h-[calc(_100vh_-_6rem)] md:h-[calc(_100vh_-_3rem)]}
.traslate-to-center-x {@apply  translate-x-[calc(50vw_-_50%)]}
.traslate-to-center-y {@apply translate-y-[calc(50vh_-_50%)]}
.traslate-to-center {@apply  translate-x-[calc(50vw_-_50%)] translate-y-[calc(50vh_-_50%)]}

.button-group__sizes {@apply flex justify-start flex-wrap}
.button-group__sizes__button {@apply border-2 overflow-hidden rounded-md h-10 w-[4rem] mr-2 mb-2}
.button-group__sizes__button.selected {@apply bg-blue-600 text-white font-semibold}

.button-group__color {@apply flex justify-start flex-wrap}
.button-group__color__button {@apply  w-16 h-16  mr-2 mb-2  truncate}
.button-group__color__button.selected   {@apply border-b-2   border-blue-600}

.button-group__filters {@apply flex space-x-2 w-fit }
.button-group__filters__button {@apply border-2 w-10 h-10 p-2 rounded-md overflow-hidden}
.button-group__filters__button.selected {@apply bg-green-marine}

.portal__modal {@apply fixed flex flex-col shadow-sm  rounded-md  transition-transform duration-300 ease-in-out transform scale-0  bg-white traslate-to-center top-0 z-30}
.portal__modal.active {@apply transform scale-100}
.portal__modal__head {@apply sticky top-0 rounded-t-md h-12 flex justify-between items-center bg-black-light text-white p-2}
.portal__modal__body {@apply rounded-b-md flex-1 overflow-auto  p-2 w-full h-full}
.portal__modal__body--no-rounded {@apply overflow-auto p-4 flex-1 h-full w-full}
.portal_modal_foot {@apply sticky  bottom-0 flex items-center w-full p-4 space-x-2  rounded-b-md}

.portal__drawer {@apply fixed flex flex-col shadow-xl transform transition duration-200 ease-in-out bg-white top-0 z-30 w-full h-screen}
.portal__drawer.small{ @apply sm:w-[30rem]}
.portal__drawer.fitContent{ @apply sm:w-fit}
.portal__drawer.left{ @apply left-0}
.portal__drawer.right{@apply right-0}
.portal__drawer.active {@apply transform scale-100}
.portal__drawer.left.hide{ @apply -translate-x-full}
.portal__drawer.right.hide{ @apply translate-x-full}
.portal__drawer__head {@apply sticky top-0 w-full h-12 flex justify-between items-center bg-gray-dark  text-white p-2}
.portal__drawer__body {@apply overflow-auto  p-4 w-full h-full }

.portal__spotlight {@apply  w-80 sm:w-96 transition-transform duration-300 ease-in-out transform scale-0 traslate-to-center-x fixed z-30 top-10}
.portal__spotlight.active {@apply transform scale-100}
.portal__spotlight__head {@apply rounded-md p-2 shadow-md mb-4 bg-white relative}
.portal__spotlight__head-content {@apply flex items-center space-x-3}
.portal__spotlight__body {@apply max-h-96 rounded-md p-2 shadow-md overflow-auto bg-white}
.portal__spotlight__body-content {@apply flex overflow-y-auto}
.portal__spotlight__body-loader {@apply flex items-center justify-center}

.portal__carousel {@apply landscape:sm:w-5/12 lg:w-8/12 xl:w-5/12 h-fit fixed z-30 top-0 traslate-to-center transition-transform 
    duration-300 ease-in-out transform scale-0 rounded-md shadow-md  bg-gray-100 overflow-hidden
}
.portal__carousel.active {@apply transform scale-100}

.tool-modal {@apply flex flex-col transition-transform duration-300 ease-in-out transform scale-0 z-20 bg-gray-100 rounded-md shadow-md overflow-hidden}
.tool-modal.active{@apply transform scale-100}
.tool-modal__head{@apply  h-12 flex justify-end items-center p-2}
.tool-modal__body{@apply flex-1 p-2 overflow-auto}

.filter__product__documents {
    @apply absolute top-16 right-0 h-fit rounded-md shadow-sm p-2
    bg-gray-200 transition-transform duration-300 ease-in-out transform scale-0
}
.filter__product__documents.active {@apply transform scale-100}

.pagination__container{@apply flex flex-wrap w-fit}
.pagination__container__button {@apply bg-gray-200 p-1 w-10 rounded-md flex justify-center mr-2 mb-2}
.pagination__container__button.selected {@apply bg-blue-600 text-white}

.notification__container {@apply fixed top-0 right-0 w-80 h-fit max-h-screen overflow-y-auto overflow-x-hidden z-40}

.notification__message {@apply w-full h-fit mb-4 rounded-md bg-slate-50 overflow-auto shadow-md  transition-transform duration-300 ease-in-out z-10}
.notification__message__header{ @apply w-full h-12 p-2 flex justify-between}
.notification__message__body {@apply w-full  h-full p-2 mb-2}
.notification__message__footer {@apply w-full h-2 self-end}
.notification__message__footer.SUCCESS{@apply bg-green-500}
.notification__message__footer.ERROR{@apply bg-red-500}
.notification__message__footer.WARNING{@apply bg-yellow-400}
.notification__message.hide {@apply translate-x-full}

.navigation__nav {@apply text-white flex-1 overflow-y-auto }
.navigation__nav > ul {@apply block mb-2 space-y-2 }
.navigation__nav ul li ul > li {@apply mb-1}
.navigation__nav ul li ul > li a {@apply block p-2 hover:bg-blue-600 rounded-md}
.navigation__nav ul li ul > li .active{@apply bg-blue-600}
.navigation__nav ul.submenuItem {@apply ml-3 mb-2}
.navigation__nav ul.submenuItem li {@apply my-2 }
.toolbar-button {@apply  w-12 h-12  flex items-center justify-center}

.aside-modal {@apply full-h-screen flex flex-col overflow-hidden  left-0 md:left-12 bottom-0 
  bg-gray-100 shadow-md p-2 fixed  transform transition duration-200 ease-in-out z-10
}
.aside-modal.hide{@apply -translate-x-full}

.portal_carousel_viewer {@apply fixed flex flex-col items-center justify-center top-0 z-30 w-screen h-screen 
    transition-transform duration-300 ease-in-out transform scale-0 bg-black-light
}
.portal_carousel_viewer.active {@apply transform scale-100}

.diaSurtido {
    display: flex;
    justify-content: center;
    width: 100px;
    min-width: 100px;
    padding: 2px 5px;
}

.diaSurtido.porcentajes{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 400px;
    min-width: 100px;
    padding: 2px 5px;
    position: relative;
}

.diaSurtido.tienda {
    justify-content: left !important;
    width: 280px !important;
    min-width: 150px !important;
}

.status_cicle {@apply w-4 h-4 rounded-full border}
.status_cicle.PENDIENTE {@apply bg-yellow-400 border-yellow-500}
.status_cicle.EN_PROCESO {@apply bg-sky-500 border-sky-600}
.status_cicle.ERROR {@apply bg-red-500 border-red-600}
.status_cicle.PROCESADO {@apply bg-green-500 border-green-600}
.status_cicle.PROCESADO_SIN_DOC {@apply bg-green-300 border-green-400}
.status_cicle.INACTIVO {@apply bg-gray-400 border-gray-500}

.status_desc {@apply font-bold text-lg}
.status_desc.PENDIENTE {@apply text-yellow-400}
.status_desc.EN_PROCESO {@apply text-sky-500}
.status_desc.ERROR {@apply text-red-500}
.status_desc.PROCESADO {@apply text-green-500}
.status_desc.PROCESADO_SIN_DOC {@apply text-green-300}
.status_desc.INACTIVO {@apply text-gray-400}
