.document-table { @apply w-full text-left text-sm  xl:table-auto}
.document-table caption {@apply text-left text-lg font-semibold border-b pb-1 mb-4}
.document-table thead {@apply hidden text-gray-600 sm:table-header-group truncate}
.document-table thead tr th{@apply p-2}
.document-table tbody tr {@apply block mb-4 p-2 border rounded-md sm:table-row sm:p-0 sm:rounded-none sm:mb-0 sm:border-0  sm:border-b  }
.document-table tbody tr td {@apply grid grid-cols-2 self-end sm:p-2 pb-3 pt-3 sm:table-cell}
@media screen and (max-width: 640px) {
    .document-table tbody tr td::before{content: attr(data-column-title); color: #000; font-weight:700; justify-self: start;}
}

.simple-table {@apply w-full text-sm  xl:w-6/12}
.simple-table tr td{@apply p-2 border}
.simple-table tr td:first-child{@apply font-semibold}
.simple-table tr td:last-child{@apply text-gray-700}